import React, { useEffect, useState } from "react";
import { ReactTransliterate } from "../third-party/react-transliterate";
import {
  Box,
  Button,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import CheckIcon from "@mui/icons-material/Check";
import Suggestions from "./Suggestions";
import { useDispatch, useSelector } from "react-redux";
import { currentSelectedLanguage } from "../features/selectedLanguage/languageSlice";
import { getTranslatedText } from "../features/translatedText/translatedTextSlice";
import { translationPatch } from "../api/translationPatch";
import { fetchPanelList } from "../features/panels/panelsSlice";
import { useParams } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const TranslateComponent = ({ handleNext }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [smartKeyboard, setSmartKeyboard] = useState(true);
  const [isSuggestions, setIsSuggestion] = useState(true);

  const translatedTextData = useSelector(getTranslatedText);
  const lang = useSelector(currentSelectedLanguage);
  const [text, setText] = useState("");
  const [originalText, setOriginalText] = useState(""); // Store the initial selected text
  const [originalSource, setOriginalSource] = useState(""); // Store the initial selected source
  const [comicTextId, setComicTextId] = useState();
  const [suggest, setSuggest] = useState([]);
  const [languageNotes, setLanguageNotes] = useState({});
  const [modified, setModified] = useState(false);

  useEffect(() => {
    // Fisher-Yates Shuffle Algorithm
    const shuffleArray = (array) => {
      const shuffled = [...array];
      for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Random index
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; // Swap elements
      }
      return shuffled;
    };
    
    translatedTextData.forEach((element) => {
      if (element.language === lang) {
        setComicTextId(element.id);

        // Set text and originalText from translation_final
        const initialText = element.translation_final?.text || "";
        setText(initialText);
        setOriginalText(initialText); // Initial loaded text is considered the "original"
        setOriginalSource(element.translation_final?.version || ""); // Track original source
        setModified(false); // Initial load should not mark as modified

        // Shuffle and set suggestions
        const randomizedSuggestions = shuffleArray(element.translation_ai_options || []);
        setSuggest(randomizedSuggestions);

        // Set language notes
        setLanguageNotes(element.language_notes || {});
      }
    });
  }, [lang, translatedTextData]);

  const handleClick = (textOption, optionSource) => {
    setText(textOption);
    setOriginalText(textOption); // Set the selected suggestion as the "original" text
    setOriginalSource(optionSource); // Set the selected source as the "original" source
    setModified(false); // Reset modified since this is now the original text
  };

  const handleQuickSubmit = (textOption, optionSource) => {
    setText(textOption);
    setOriginalText(textOption);
    setOriginalSource(optionSource);
    setModified(false);
    handleSubmit(textOption, optionSource);
  };

  const handleSubmit = async (quickText = null, quickSource = null) => {
    const finalText = quickText || text;
    const finalSource = quickSource || originalSource || "gpt"; // Default to 'gpt' if no source provided

    // Set modified only if finalText differs from originalText
    const isModified = finalText !== originalText;
    setModified(isModified);

    const data = {
      translation_final: {
        version: finalSource, // Use the selected or default source
        text: finalText,
        modified: isModified,
      },
    };
    translationPatch(data, comicTextId);
    dispatch(fetchPanelList(id));
    handleNext();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column-reverse",
        width: "100%",
        height: "100%",
        gap: "1rem",
        overflowY: "auto",
      }}
    >
        <Paper sx={{ border: "1px solid #737373", borderRadius: "10px" }}>
          <Box>
            {smartKeyboard ? (
                <ReactTransliterate
                    renderComponent={(props) => {
                      const inputRef = props.ref;
                      delete props["ref"];
                      return (
                          <TextField
                              autoFocus
                              multiline
                              fullWidth
                              rows={6}
                              variant="filled"
                              {...props}
                              inputRef={inputRef}
                              defaultValue={text}
                              onChange={(e) => {
                                const newText = e.target.value;
                                setText(newText);
                              }}
                          />
                      );
                    }}
                    value={text}
                    onChangeText={(newText) => {
                      setText(newText);
                    }}
                    lang={lang}
                />
            ) : (
                <TextField
                    autoFocus
                    multiline
                    fullWidth
                    rows={6}
                    variant="filled"
                    defaultValue={text}
                    onChange={(e) => {
                      const newText = e.target.value;
                      setText(newText);
                    }}
                />
            )}
          </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: ".5rem 1rem",
          }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: "2rem" }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                    defaultChecked
                onChange={() => {
                  setSmartKeyboard(!smartKeyboard);
                }}
                    inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>Smart Keyboard</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <AntSwitch
                    defaultChecked
                onChange={() => {
                  setIsSuggestion(!isSuggestions);
                }}
                    inputProps={{ "aria-label": "ant design" }}
                />
                <Typography>Suggestions</Typography>
              </Stack>
            </Box>
            <Box>
              <Button
                  variant="contained"
                  endIcon={<CheckIcon />}
                  sx={{ borderRadius: "50px" }}
              onClick={() => {
                handleSubmit();
              }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Paper>

        {isSuggestions && (
        <Paper
          sx={{
            borderRadius: "10px",
            overflowY: "auto",
          }}
        >
              <Suggestions
                  data={suggest}
                  handleClick={(text, source) => handleClick(text, source)}
                  handleQuickSubmit={(text, source) => handleQuickSubmit(text, source)}
              />
            </Paper>
        )}
      </Box>
  );
};

export default TranslateComponent;
