import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import TextCard from "./TextCard";
import { useState } from "react";
import TranslateComponent from "./TranslateComponent";
import {
  getPanelListStatus,
  selectPanelList,
  selectedTextCardIndex,
  selectedPageCardIndex,
} from "../features/panels/panelsSlice";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useKeyboardShortcut from "use-keyboard-shortcut";

import {
  fetchTranslatedText,
  getTranslatedTextStatus,
} from "../features/translatedText/translatedTextSlice";
import { useEffect } from "react";
import { currentSelectedLanguage } from "../features/selectedLanguage/languageSlice";
import Loader from "./Loader";
import KonvaCanvas from "./KonvaCanvas";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function TranslationPanels() {
  const [value, setValue] = useState(0);
  const [comicIndex, setComicIndex] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const data = useSelector(selectPanelList);
  const translatedTextStatus = useSelector(getTranslatedTextStatus);
  const lang = useSelector(currentSelectedLanguage);
  const status = useSelector(getPanelListStatus);

  const dispatch = useDispatch();
  const selectedTextIndex = useSelector(selectedTextCardIndex);
  const selectedPageIndex = useSelector(selectedPageCardIndex);

  const { flushHeldKeys } = useKeyboardShortcut(
    ["ArrowRight"],
    (shortcutKeys) => {
      setComicIndex(
        Math.min(comicIndex + 1, data[value].comic_texts.length - 1)
      );
    },
    {
      overrideSystem: false,
      ignoreInputFields: true,
      repeatOnHold: false,
    }
  );
  const { flushHeldKey } = useKeyboardShortcut(
    ["ArrowLeft"],
    (shortcutKeys) => {
      setComicIndex(Math.max(comicIndex - 1, 0));
    },
    {
      overrideSystem: false,
      ignoreInputFields: true,
      repeatOnHold: false,
    }
  );

  const [isExpanded, setIsExpanded] = useState(false);
  
  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (data[value]) {
      if (data[value].comic_texts.length > 0) {
        if (data[value].comic_texts[comicIndex]?.id) {
          dispatch(
            fetchTranslatedText(data[value].comic_texts[comicIndex]?.id)
          );
        }
      }
    }
  }, [value, comicIndex, data, dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setComicIndex(0);
  };

  const onNextClick = () => {
    setValue(value + 1);
  };

  const handleClick = (newValue) => {
    setValue(newValue);
    setComicIndex(0);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      setOpenSnackbar(false);
      return;
    }
    setOpenSnackbar(false);
  };

  const handleNext = () => {
    if (comicIndex < data[value].comic_texts.length - 1) {
      setComicIndex(comicIndex + 1);
    } else {
      setComicIndex(0);
      if (value < data.length - 1) {
        setValue(value + 1);
      } else {
        setOpenSnackbar(true);
      }
    }
  };

  // used to set the selected box index to comicIndex state, to show the selected box text on the text card
  const handleBBoxClick = (rectDimensions) => {
    setComicIndex(rectDimensions);
  };
  return (
    <Paper elevation={0} sx={{ width: "100%" }}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
          All panels have been reviewed, press "Submit for Export".
        </Alert>
      </Snackbar>
      <Grid container sx={{ alignItems: "center", marginTop: "0.75rem" }}>
        <Grid item xs={10} md={10}>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                height: ".1rem",
              },
            }}
          >
            {data &&
              data.map((panel, index) => (
                <Tooltip title={index + 1}>
                  <IconButton
                    onClick={() => handleClick(index)}
                    disabled={
                      selectedTextIndex != null && selectedPageIndex != null
                    }
                    size="small"
                    color={value === index ? "primary" : undefined}
                    disableRipple
                  >
                    {panel.is_reviewed_translation[lang] ? (
                      <CheckCircleIcon
                        color={value !== index ? "success" : "primary"}
                        fontSize="large"
                      />
                    ) : (
                      <Paper
                        sx={{
                          borderRadius: "50%",
                          aspectRatio: 1,
                          width: "1.6rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          bgcolor: value === index ? "primary.main" : undefined,
                          color:
                            value === index
                              ? "primary.contrastText"
                              : undefined,
                        }}
                      >
                        {index + 1}
                      </Paper>
                    )}
                  </IconButton>
                </Tooltip>
              ))}
          </Stack>
        </Grid>
      </Grid>
      <Box display="flex" sx={{ width: "100%" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ height: "80vh" }}
        >
          {data.map((panel) => (
            <Tab
              disableRipple
              icon={
                <Paper
                  sx={{
                    minHeight: "5rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #676767",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={panel.panel_composite_image_small || panel.panel_composite_image}
                    alt=""
                    style={{
                      height: "auto",
                      width: "5rem",
                    }}
                  />
                </Paper>
              }
              {...a11yProps(panel.id)}
            />
          ))}
        </Tabs>
        <Box style={{ width: "100%" }}>
          {data &&
            data.map((panel, index) => {
              return (
                <TabPanel value={value} index={index}>
                  {status === "succeeded" ? (
                    <Grid
                      container
                      spacing={2}
                      sx={{ height: "80vh", m: 0, p: 0 }}
                    >
                      <Grid item xs={isExpanded ? 9 : 6} sx={{ height: "100%" }}>
                        <Paper
                          elevation={1}
                          style={{
                            height: "100%",
                            width: "100%",
                            // display: "flex",
                            // justifyContent: "center",
                            // alignItems: "center",
                            overflow: "hidden",
                          }}
                        >
                          <KonvaCanvas
                            boxes={panel.comic_texts.map(
                              (item) => item.bounding_box
                            )}
                            url={panel.panel_composite_image}
                            panelDims={panel.meta.panel_dims}
                            selectedBoxIndex={comicIndex}
                            handleBBoxClick={handleBBoxClick}
                            isExpanded={isExpanded}
                            handleExpand={handleExpand}
                          />
                        </Paper>
                      </Grid>
                      <Grid item xs={isExpanded ? 3 : 6} sx={{ height: "100%" }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            width: "100%",
                            height: "100%",
                            gap: "1rem",
                          }}
                        >
                          {panel.comic_texts[comicIndex] ? (
                            <>
                              <Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Tooltip title="Previous">
                                    <IconButton
                                      disabled={comicIndex === 0}
                                      onClick={() => {
                                        setComicIndex(comicIndex - 1);
                                      }}
                                    >
                                      <KeyboardArrowLeftIcon />
                                    </IconButton>
                                  </Tooltip>

                                  {`${comicIndex + 1} / ${panel.comic_texts.length
                                    }`}
                                  <Tooltip title="Next">
                                    <IconButton
                                      disabled={
                                        comicIndex >=
                                        data[value].comic_texts.length - 1
                                      }
                                      onClick={() => {
                                        setComicIndex(comicIndex + 1);
                                      }}
                                    >
                                      <KeyboardArrowRightIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                <TextCard
                                  id={panel.comic_texts[comicIndex].index}
                                  text={
                                    panel.comic_texts[comicIndex].text_cleaned
                                  }
                                  editable={false}
                                />
                              </Box>
                              {translatedTextStatus === "succeeded" && (
                                <TranslateComponent handleNext={handleNext} />
                              )}
                            </>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                alignItems: "flex-end",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                variant="contained"
                                onClick={onNextClick}
                                sx={{ width: "50%" }}
                              >
                                Next
                              </Button>
                            </Box>
                          )}
                        </Box>
                        {/* </div> */}
                      </Grid>
                    </Grid>
                  ) : (
                    <Loader />
                  )}
                </TabPanel>
              );
            })}
        </Box>
      </Box>
    </Paper>
  );
}
