import React, { useEffect, useRef, useState } from "react";
import { IconButton, Paper, Tooltip } from "@mui/material";
import CropFreeOutlinedIcon from "@mui/icons-material/CropFreeOutlined";
import { Stage, Layer, Rect } from "react-konva";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { selectedTextCardIndex, selectedPageCardIndex } from "../features/panels/panelsSlice";
import { useSelector } from "react-redux";

const BoundingRect = (props) => {
  const [strokeColor, setStrokeColor] = useState("red");

  return (
    <Rect
      x={props.x}
      y={props.y}
      width={props.width}
      height={props.height}
      fill="transparent"
      onClick={props.onClick}
      onMouseEnter={() => setStrokeColor("blue")}
      onMouseLeave={() => setStrokeColor("red")}
      stroke={strokeColor}
      strokeWidth={2}
      style={{ cursor: "pointer" }}
    />
  );
};

const KonvaCanvas = ({
  url,
  panelDims,
  boxes,
  handleBBoxClick,
  crop,
  setCrop,
  pageIndex,
  isExpanded,
  handleExpand,
}) => {
  const selectedTextIndex = useSelector(selectedTextCardIndex);
  const selectedPageIndex = useSelector(selectedPageCardIndex);
  const [rectCoordinates, setRectCoordinates] = useState([]);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [contracteddimensions, setContracteddimensions] = useState({
    width: 0,
    height: 0,
  });

  const containerRef = useRef();
  const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });
  const aspectRatio = panelDims[0] / panelDims[1];

  useEffect(() => {
    setContracteddimensions({
      width: containerRef.current.offsetWidth,
      height: containerRef.current.offsetHeight,
    });
  }, []);

  useEffect(() => {
    if (isExpanded && containerRef.current?.offsetHeight && containerRef.current?.offsetWidth) {
      setDimensions({
        width: containerRef.current.offsetWidth,
        height: containerRef.current.offsetHeight,
      });
    }
    else if(!isExpanded)
    {
      setDimensions({
        width: contracteddimensions.width,
        height: contracteddimensions.height,
      });
    }
  }, [isExpanded, containerRef, contracteddimensions]);

  useEffect(() => {
    const factorX = dimensions.width / panelDims[0];
    const factorY = dimensions.height / panelDims[1];
    const coordinates = boxes.map((box) => {
      const [left, top, right, bottom] = box;
      return {
        x: left * factorX,
        y: top * factorY,
        width: (right - left) * factorX,
        height: (bottom - top) * factorY,
      };
    });
    setRectCoordinates(coordinates);
  }, [dimensions, contracteddimensions, isExpanded]);

  const repositionStage = () => {
    if (containerRef.current) {
      const dx = containerRef.current.scrollLeft;
      const dy = containerRef.current.scrollTop;
      setScrollPosition({ x: dx, y: dy });
    }
  };

  useEffect(() => {
    const scrollContainer = containerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", repositionStage);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", repositionStage);
      }
    };
  }, []);

  return (
    <Paper
      elevation={1}
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
        position: "relative",
      }}
    >
      <div
        ref={containerRef}
        style={{
          aspectRatio: aspectRatio,
          maxHeight: undefined,
          maxWidth: undefined,
          width: "90%",
          backgroundImage: `url(${url})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          zIndex: "2",
          margin: "auto",
        }}
      >
        {selectedTextIndex !== null && selectedPageIndex === pageIndex ? (
          <ReactCrop
            crop={crop}
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              margin: "auto",
              aspectRatio: panelDims[0] / panelDims[1],
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onChange={(crop, percentCrop) => setCrop(percentCrop)}
          >
            <img style={{ height: "100%", width: "100%" }} src={url} alt="" />
          </ReactCrop>
        ) : (
          <Stage
            width={dimensions.width}
            height={dimensions.height}
            x={-scrollPosition.x} // Adjust stage position based on scroll
            y={-scrollPosition.y} // Adjust stage position based on scroll
          >
            <Layer width={dimensions.width} height={dimensions.height}>
              {rectCoordinates?.map((rect, index) => {
                return (
                  <BoundingRect
                    key={index}
                    x={rect.x}
                    y={rect.y}
                    width={rect.width}
                    height={rect.height}
                    onClick={() => handleBBoxClick(index)}
                  />
                );
              })}
            </Layer>
          </Stage>
        )}
      </div>
      <Tooltip title={isExpanded ? "Contract" : "Zoom"}>
        <IconButton
          aria-label="delete"
          size="small"
          sx={{
            bgColor: "rgba(0,0,0,0.5)",
            top: 10,
            right: 10,
            position: "absolute",
          }}
          onClick={handleExpand}
        >
          <CropFreeOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </Paper>
  );
};

export default KonvaCanvas;
