import * as React from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState, useCallback } from "react";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getPanelListStatus,
  selectPanelList,
  selectedTextCardIndex,
  selectedPageCardIndex,
  createNewStatus,
  fetchPanelList,
  updateIndex,
  resetIndex,
  setData,
  toggleCreateNew,
} from "../features/panels/panelsSlice";
import { comicTextPatch } from "../api/comicTextPatch";
import { useParams } from "react-router-dom";
import Loader from "./Loader";
import { panelPatchReview } from "../api/panelPatchReview";
import { comicTextDelete } from "../api/comicTextDelete";
import { comicTextsReorder } from "../api/comicTextsReorder";
import { useEffect } from "react";
import { comicTextCreate } from "../api/comicTextCreate";
import KonvaCanvas from "./KonvaCanvas";
import { Card } from "./Card";
import useKeyboardShortcut from "use-keyboard-shortcut";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Panels() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [change, setChange] = useState(false);
  const [currIndex, setCurrIndex] = useState(null);
  const selectedTextIndex = useSelector(selectedTextCardIndex);
  const selectedPageIndex = useSelector(selectedPageCardIndex);
  const data = useSelector(selectPanelList);
  const status = useSelector(getPanelListStatus);
  const [isLoading, setIsLoading] = useState(false);
  const [currTextIndex, setCurrTextIndex] = useState(0);
  const statusCreateNew = useSelector(createNewStatus);
  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (value !== null) {
      setCurrTextIndex(0);
    }
  }, [value]);


  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  useKeyboardShortcut(
    ["ArrowDown"],
    (shortcutKeys) => {
      if (selectedTextIndex == null && selectedPageIndex == null) {
        setCurrTextIndex(
          currTextIndex < data[value].comic_texts.length - 1
            ? currTextIndex + 1
            : currTextIndex
        );
      }
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  useKeyboardShortcut(
    ["ArrowUp"],
    (shortcutKeys) => {
      if (selectedTextIndex == null && selectedPageIndex == null) {
        setCurrTextIndex(currTextIndex > 0 ? currTextIndex - 1 : 0);
      }
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  useKeyboardShortcut(
    ["Enter"],
    (shortcutKeys) => {
      if (
        selectedTextIndex == null &&
        selectedPageIndex == null &&
        data[value].comic_texts.length > 0
      ) {
        handleBBoxClick(currTextIndex, value);
      }
    },
    {
      overrideSystem: true,
      ignoreInputFields: true,
      repeatOnHold: false,
    }
  );

  const handleEditClick = (textIndex, pageIndex) => {
    const width = data[pageIndex].meta.panel_dims[0];
    const height = data[pageIndex].meta.panel_dims[1];
    const [left, top, right, bottom] =
      data[pageIndex].comic_texts[textIndex].bounding_box;
    const x = (left / width) * 100;
    const y = (top / height) * 100;
    const w = ((right - left) / width) * 100;
    const h = ((bottom - top) / height) * 100;
    const newCrop = {
      unit: "%",
      x: x,
      y: y,
      width: w,
      height: h,
    };
    setCrop(newCrop);
  };

  useEffect(() => {
    if (statusCreateNew && status === "loading") {
      setIsLoading(true);
    }
  }, [statusCreateNew, status]);

  useEffect(() => {
    if (statusCreateNew && status === "succeeded" && isLoading) {
      handleBBoxClick(data[value].comic_texts.length - 1, value);
      dispatch(toggleCreateNew());
      setIsLoading(false);
    }
  }, [statusCreateNew, status]);

  const handleCreateNew = async (pageIndex) => {
    const newData = {
      index: data[pageIndex].comic_texts.length,
      text_cleaned: "",
      bounding_box: [
        parseInt(data[pageIndex].meta.panel_dims[0] / 4),
        parseInt(data[pageIndex].meta.panel_dims[1] / 4),
        parseInt(3 * (data[pageIndex].meta.panel_dims[0] / 4)),
        parseInt(3 * (data[pageIndex].meta.panel_dims[1] / 4)),
      ],
      panel: data[pageIndex].id,
      is_custom: true,
    };
    await comicTextCreate(newData);
    dispatch(fetchPanelList(id));
  };

  const handleChange = (event, newValue) => {
    dispatch(resetIndex());
    setValue(newValue);
  };

  const handleDone = async (state) => {
    if (state === false) {
      setValue(value + 1);
    }
    let id = data[value].id;
    let dt = {
      is_reviewed: !state,
    };
    await panelPatchReview(id, dt);
    setChange(true);
  };

  const handleDelete = async (textIndex, pageIndex) => {
    const comicTextId = data[pageIndex].comic_texts[textIndex].id;
    await comicTextDelete(comicTextId);
    dispatch(resetIndex());
    setChange(true);
  };

  useEffect(() => {
    if (change) {
      dispatch(fetchPanelList(id));
      setChange(false);
    }
  }, [change, dispatch, id]);

  const handleClick = (textIndex) => {
    setValue(textIndex);
    dispatch(resetIndex());
  };
  const handleEditSubmit = async (textIndex, pageIndex, newText) => {
    const width = data[pageIndex].meta.panel_dims[0];
    const height = data[pageIndex].meta.panel_dims[1];
    const comic_text = data[pageIndex].comic_texts[textIndex];
    const left = Math.round((crop.x * width) / 100);
    const top = Math.round((crop.y * height) / 100);
    const right = Math.round(((crop.x + crop.width) * width) / 100);
    const bottom = Math.round(((crop.y + crop.height) * height) / 100);
    const newBoundingBox = [left, top, right, bottom];
    const newComicText = {
      ...comic_text,
      bounding_box: newBoundingBox,
      text_cleaned: newText,
    };
    await comicTextPatch(newComicText, comic_text.id);
    dispatch(fetchPanelList(id));
    dispatch(resetIndex());
  };

  const handleBBoxClick = (textIndex, pageIndex) => {
    dispatch(updateIndex({ textIndex: textIndex, pageIndex: pageIndex }));
    handleEditClick(textIndex, pageIndex);
  };

  const handleClearAll = () => {
    Promise.all(
      data[value].comic_texts.map((element, textIndex) => {
        const comicTextId = data[value].comic_texts[textIndex].id;
        return comicTextDelete(comicTextId);
      })
    )
      .then(() => {})
      .catch((errs) => {
        console.error("ERROR-CLR_ALL", errs);
      })
      .finally(() => {
        setOpen(false);
        setChange(true);
      });
  };
  const handleOrderReset = () => {
    dispatch(fetchPanelList(id));
    dispatch(resetIndex());
    setCurrIndex(null);
  };

  const handleReorder = () => {
    const mappedData = data[currIndex].comic_texts.reduce(
      (object, element, textIndex) => {
        object[element.id] = {
          prevIndex: element.index,
          newIndex: textIndex,
        };
        return object;
      },
      {}
    );
    comicTextsReorder(mappedData, data[currIndex].id)
      .then((res) => {})
      .catch((err) => {})
      .finally(() => {
        dispatch(fetchPanelList(id));
        dispatch(resetIndex());
      });
    setCurrIndex(null);
  };

  const moveCard = useCallback(
    (dragIndex, hoverIndex, pageIndex) => {
      setCurrIndex(pageIndex);
      dispatch(
        setData(
          update(data, {
            [pageIndex]: {
              comic_texts: {
                $splice: [
                  [dragIndex, 1],
                  [hoverIndex, 0, data[pageIndex].comic_texts[dragIndex]],
                ],
              },
            },
          })
        )
      );
    },
    [data, dispatch]
  );

  const renderCard = useCallback(
    (el, textIndex, pageIndex) => {
      return (
        <Card
          key={el.index}
          index={textIndex}
          pageIndex={pageIndex}
          id={el.index}
          text={el.text_cleaned}
          moveCard={(dragIndex, hoverIndex) =>
            moveCard(dragIndex, hoverIndex, pageIndex)
          }
          onEdit={() => {
            handleEditClick(textIndex, pageIndex);
            dispatch(
              updateIndex({ textIndex: textIndex, pageIndex: pageIndex })
            );
          }}
          onSubmitClick={(newText) => {
            handleEditSubmit(textIndex, pageIndex, newText);
            dispatch(resetIndex());
          }}
          handleDelete={() => {
            handleDelete(textIndex, pageIndex);
          }}
          isFocused={textIndex === currTextIndex}
        />
      );
    },
    [handleEditClick, handleEditSubmit, handleDelete]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container sx={{ alignItems: "center", marginTop: "0.75rem" }}>
        <Grid item xs={8} md={8}>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              overflowX: "auto",
              "&::-webkit-scrollbar": {
                height: ".1rem",
              },
            }}
          >
            {data &&
              data.map((panel, pageIndex) => (
                <Tooltip title={pageIndex + 1}>
                  <IconButton
                    onClick={() => handleClick(pageIndex)}
                    disabled={
                      selectedTextIndex != null && selectedPageIndex != null
                    }
                    size="small"
                    disableRipple
                  >
                    {panel.is_reviewed ? (
                      <CheckCircleIcon
                        color={value !== pageIndex ? "success" : "primary"}
                        fontSize="large"
                      />
                    ) : (
                      <Paper
                        elevation={3}
                        sx={{
                          borderRadius: "50%",
                          aspectRatio: 1,
                          width: "1.6rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          bgcolor:
                            value === pageIndex ? "primary.main" : undefined,
                          color:
                            value === pageIndex
                              ? "primary.contrastText"
                              : undefined,
                        }}
                      >
                        {pageIndex + 1}
                      </Paper>
                    )}
                  </IconButton>
                </Tooltip>
              ))}
          </Stack>
        </Grid>
        <Grid item xs={4} md={4}>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (data[value]) {
                  handleDone(data[value].is_reviewed);
                }
              }}
              disabled={selectedTextIndex != null && selectedPageIndex != null}
            >
              {data[value] && data[value].is_reviewed ? (
                <>Mark as Undone</>
              ) : (
                <>Mark as done</>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
      <Box display="flex" sx={{ width: "100%", maxHeight: "90%" }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          sx={{ height: "80vh" }}
        >
          {data &&
            data.map((panel) => (
              <Tab
                disableRipple
                icon={
                  <Paper
                    sx={{
                      minHeight: "5rem",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #676767",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={panel.panel_composite_image_small || panel.panel_composite_image}
                      alt=""
                      style={{
                        height: "auto",
                        width: "5rem",
                      }}
                    />
                  </Paper>
                }
                {...a11yProps(panel.id)}
                disabled={
                  selectedTextIndex != null && selectedPageIndex != null
                }
              />
            ))}
        </Tabs>
        <Box style={{ width: "100%" }}>
          {data &&
            data.map((panel, pageIndex) => {
              return (
                <TabPanel value={value} index={pageIndex}>
                  {status === "succeeded" ? (
                    <Grid
                      container
                      spacing={2}
                      sx={{ height: "80vh", m: 0, p: 0 }}
                    >
                      <Grid item xs={isExpanded ? 9 : 6} sx={{ height: "100%" }}>
                        <KonvaCanvas
                          boxes={panel.comic_texts.map(
                            (item) => item.bounding_box
                          )}
                          url={panel.panel_composite_image}
                          panelDims={panel.meta.panel_dims}
                          handleBBoxClick={(textIndex) =>
                            handleBBoxClick(textIndex, pageIndex)
                          }
                          crop={crop}
                          setCrop={setCrop}
                          // textIndex={textIndex}
                          pageIndex={pageIndex}
                          handleExpand={handleExpand}
                          isExpanded={isExpanded}
                        />
                      </Grid>
                      <Grid item xs={isExpanded ? 3 : 6} sx={{ height: "100%" }}>
                        <Stack direction="row-reverse" gap={1} mb="0.5rem">
                          <Button
                            variant="contained"
                            onClick={handleClickOpen}
                            disabled={
                              selectedTextIndex != null &&
                              selectedPageIndex != null
                            }
                            size="small"
                          >
                            Clear All
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              handleCreateNew(pageIndex);
                              dispatch(toggleCreateNew());
                            }}
                            disabled={selectedTextIndex != null}
                            size="small"
                          >
                            Create New
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleOrderReset}
                            disabled={
                              (selectedTextIndex != null &&
                                selectedPageIndex != null) ||
                              currIndex == null
                            }
                            size="small"
                          >
                            Reset Reorder
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleReorder}
                            disabled={
                              (selectedTextIndex != null &&
                                selectedPageIndex != null) ||
                              currIndex == null
                            }
                            size="small"
                          >
                            Apply Reorder
                          </Button>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Delete all detected texts from this page/panel?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button variant="outlined" onClick={handleClose}>
                                No
                              </Button>
                              <Button
                                variant="contained"
                                onClick={handleClearAll}
                                autoFocus
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Stack>
                        <Stack
                          style={{ height: "95%", overflowY: "auto" }}
                          spacing={2}
                        >
                          {panel.comic_texts.map((el, textIndex) =>
                            renderCard(el, textIndex, pageIndex)
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  ) : (
                    <Loader />
                  )}
                </TabPanel>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}
